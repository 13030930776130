/* eslint-disable no-unused-vars */
import firebase from "firebase/app";

export const state = {
  eventSummarys: [],
  festivalEventSummarys: [],
  eventSummary: {
    id: null,
    performerId: null,
    performerName: "",
    name: "",
    description: "",
    venue: "",
    step: 1,
    stepsCompleted: 0,
    minPrice: 0,
    maxPrice: 0,
    startDate: new Date()
  },
  dateSummaries: [],
  dateSummary: {},
  willcall: [],
  waitlist: [],
  audience: [],
  checkedin: [],
  cancelled: []
};

var audienceRef = null;
var audienceListener = null;
var willcallListener = null;
var checkedinListener = null;
var cancelledListener = null;

export const mutations = {
  ADD_EVENT_SUMMARY(state, summary) {
    state.eventSummarys.push(summary);
  },
  SET_EVENT_SUMMARY(state, event) {
    state.eventSummary = event;
  },
  CLEAR_EVENT_SUMMARIES(state) {
    state.eventSummarys = [];
  },
  ADD_FESTIVAL_EVENT_SUMMARY(state, summary) {
    state.festivalEventSummarys.push(summary);
  },
  CLEAR_FESTIVAL_EVENT_SUMMARIES(state) {
    state.eventSummarys = [];
  },
  SET_DATE_SUMMARIES(state, summaries) {
    state.dateSummaries = summaries;
  },
  SET_DATE_SUMMARY(state, summary) {
    state.dateSummary = summary;
  },
  SET_WILL_CALL(state, willcall) {
    state.willcall = willcall;
  },
  CLEAR_CHECKEDIN(state) {
    state.checkedin = [];
  },
  CLEAR_CANCELLED(state) {
    state.cancelled = [];
  },
  SET_CHECKEDIN(state, checkedin) {
    state.checkedin = checkedin;
  },
  SET_CANCELLED(state, cancelled) {
    state.cancelled = cancelled;
  },
  SET_AUDIENCE(state, audience) {
    state.audience = audience;
  },
  SET_WAITLIST(state, waitlist) {
    state.waitlist = waitlist;
  }
};

export const actions = {
  async fetchEventSummary({ commit }, eventId) {
    var db = firebase.firestore();
    var audRef = db
      .collection("events")
      .doc(eventId)
      .collection("userData")
      .doc("audienceReport");
    var aud = await audRef.get();

    if (aud.exists) {
      console.log("Found the event summary.");
      commit("SET_EVENT_SUMMARY", aud.data());
    } else {
      console.log("Could not find event " + eventId);
    }
  },
  async fetchEventWaitlist({ commit }, eventId) {
    // TODO: Consider wiping all event stuff at the event level
    commit("SET_WAITLIST", []);
    console.log("Getting waitlist", eventId);
    var db = firebase.firestore();
    var waitRef = db
      .collection("events")
      .doc(eventId)
      .collection("userData")
      .doc("audienceReport")
      .collection("waitlist")
      .orderBy("position", "asc");
      var docs = await waitRef.get();

      if (!docs.empty) {
        commit(
          "SET_WAITLIST",
          docs.docs.map(doc => doc.data())
        );
      } else {
        console.log("No waitlist for this event.");
      }
  },
  async fetchEventSummarys({ commit }, profileId) {
    var db = firebase.firestore();
    console.log("Getting event summarys", profileId);
    // Clear out state
    commit("CLEAR_EVENT_SUMMARIES");

    // Get undated drafts
    var draftRef = db
      .collection("events")
      .where("owners", "array-contains", profileId)
      .where("start", "==", null);
    var drafts = await draftRef.get();

    if (!drafts.empty) {
      console.log("Found drafts. Loading summarys");
      drafts.forEach(async event => {
        const eventData = event.data();
        // console.log("event", eventData);
        // firebase doesn't allow two equality filters. doing this the weird way.
        if (eventData.status != "deleted")
          commit("ADD_EVENT_SUMMARY", {
            showMenu: false,
            event: eventData,
            audienceReport: null
          });
      });
    }

    console.log("profile id", profileId);
    // Get dated summaries
    var docRef = db
      .collection("events")
      .where("owners", "array-contains", profileId)
      .where("start", "!=", null)
      .orderBy("start", "desc");
    var docs = await docRef.get();

    if (!docs.empty) {
      console.log("Found the events. Loading summarys");
      docs.forEach(async event => {
        const eventData = event.data();
        // console.log("event", eventData);
        if (eventData.status != "deleted") {
          const summaryRef = db
            .collection("events")
            .doc(eventData.id)
            .collection("userData")
            .doc("audienceReport");
          const summary = await summaryRef.get();
          // console.log("summary", summary);
          commit("ADD_EVENT_SUMMARY", {
            showMenu: false,
            event: eventData,
            audienceReport: summary.data()
          });
        }
      });
    } else {
      console.log("Could not find any events. Not loading summarys.");
    }
  },
  async fetchFestivalEventSummarys({ commit }, seasonId) {
    var db = firebase.firestore();
    console.log("Getting festival event summarys", seasonId);
    // Clear out state
    commit("CLEAR_FESTIVAL_EVENT_SUMMARIES");

    // Get festival events
    var eventsRef = db
      .collection("events")
      .where("seasonId", "==", seasonId)
      .orderBy("title");
    var docs = await eventsRef.get();

    if (!docs.empty) {
      console.log("Found the events. Loading summarys");
      docs.forEach(async event => {
        const eventData = event.data();
        console.log("event", eventData);
        if (eventData.status != "deleted") {
          const summaryRef = db
            .collection("events")
            .doc(eventData.id)
            .collection("userData")
            .doc("audienceReport");
          const summary = await summaryRef.get();
          // console.log("summary", summary);
          commit("ADD_FESTIVAL_EVENT_SUMMARY", {
            showMenu: false,
            event: eventData,
            audienceReport: summary.data()
          });
        }
      });
    } else {
      console.log("Could not find any events. Not loading summarys.");
    }
  },
  async fetchDateSummaries({ commit }, eventId) {
    var db = firebase.firestore();
    console.log("Getting date summarys", eventId);
    var docRef = db
      .collection("events")
      .doc(eventId)
      .collection("userData")
      .doc("audienceReport")
      .collection("dates")
      .orderBy("start"); // profileId);
    var docs = await docRef.get();

    if (!docs.empty) {
      commit(
        "SET_DATE_SUMMARIES",
        docs.docs.map(doc => doc.data())
      );
    } else {
      console.log("Could not find any date summaries.");
    }
  },
  async fetchDateSummary({ commit }, request) {
    var db = firebase.firestore();
    console.log("Getting date summary", request.eventId, request.dateId);
    var docRef = db
      .collection("events")
      .doc(request.eventId)
      .collection("userData")
      .doc("audienceReport")
      .collection("dates")
      .doc(request.dateId);
    var doc = await docRef.get();

    if (doc.exists) {
      console.log("Found the date summary.");
      commit("SET_DATE_SUMMARY", doc.data());
    } else {
      console.log("Could not find event " + request.dateId);
    }
  },
  async fetchWillcall({ commit }, request) {
    var db = firebase.firestore();
    console.log("Getting will call list", request.eventId, request.dateId);
    var docRef = db
      .collection("events")
      .doc(request.eventId)
      .collection("userData")
      .doc("audienceReport")
      .collection("dates")
      .doc(request.dateId)
      .collection("audience")
      .where("status", "in", ["paid", "partiallycheckedin"])
      .orderBy("lastName");
    var docs = await docRef.get();

    if (!docs.empty) {
      commit(
        "SET_WILL_CALL",
        docs.docs.map(doc => {
          var ed = doc.data();
          if (!ed.id) ed.id = doc.id;
          return ed;
        })
      );
      willcallListener = docRef.onSnapshot(function(snapshot) {
        let update = snapshot.docs;
        console.log("will call list updated", update);
        commit(
          "SET_WILL_CALL",
          update.map(doc => {
            var ed = doc.data();
            if (!ed.id) ed.id = doc.id;
            return ed;
          })
        );
      });
    } else {
      console.log("Could not find will call list.");
    }
  },
  async checkin({ dispatch }, request) {
    var db = firebase.firestore();
    console.log("Checking patron in", request.audience, request.checkqty);

    var docRef = await db
      .collection("events")
      .doc(request.eventId)
      .collection("userData")
      .doc("audienceReport")
      .collection("dates")
      .doc(request.dateId)
      .collection("audience")
      .doc(request.audience.id);
    //var docs = await docRef.get();
    var totalchecked = request.audience.checkedin + request.checkqty;
    if (totalchecked > request.audience.quantity)
      totalchecked = request.audience.quantity;
    var remaining = request.audience.quantity - totalchecked;
    if (remaining < 0) remaining = 0;
    var status = "partiallycheckedin";
    if (remaining == 0) status = "checkedin";
    await docRef.update({ status: status, checkedin: totalchecked });
  },
  async undoCheckin({ dispatch }, request) {
    var db = firebase.firestore();
    console.log("Undoing checked in patron", request.audienceId);
    const status = request.isPass ? "cancelled" : "paid";

    var docRef = await db
      .collection("events")
      .doc(request.eventId)
      .collection("userData")
      .doc("audienceReport")
      .collection("dates")
      .doc(request.dateId)
      .collection("audience")
      .doc(request.audienceId);
    //var docs = await docRef.get();
    await docRef.update({ status: status, checkedin: 0 });
  },
  async fetchCheckedIn({ commit }, request) {
    commit("CLEAR_CHECKEDIN");
    var db = firebase.firestore();
    console.log("Getting checkedin list", request.eventId, request.dateId);
    var docRef = db
      .collection("events")
      .doc(request.eventId)
      .collection("userData")
      .doc("audienceReport")
      .collection("dates")
      .doc(request.dateId)
      .collection("audience")
      .where("status", "in", ["checkedin", "partiallycheckedin", "mixed"])
      .orderBy("lastName");
    var docs = await docRef.get();

    if (!docs.empty) {
      commit(
        "SET_CHECKEDIN",
        docs.docs.map(doc => doc.data())
      );
    } else {
      console.log("Could not find checkedin list.");
    }
    checkedinListener = docRef.onSnapshot(function(snapshot) {
      let update = snapshot.docs;
      console.log("checked in list updated", update);
      commit(
        "SET_CHECKEDIN",
        update.map(doc => {
          var ed = doc.data();
          if (!ed.id) ed.id = doc.id;
          return ed;
        })
      );
    });
  },
  async fetchCancelled({ commit }, request) {
    commit("CLEAR_CANCELLED");
    var db = firebase.firestore();
    console.log("Getting cancelled list", request.eventId, request.dateId);
    var docRef = db
      .collection("events")
      .doc(request.eventId)
      .collection("userData")
      .doc("audienceReport")
      .collection("dates")
      .doc(request.dateId)
      .collection("audience")
      .where("status", "in", ["cancelled", "mixed"])
      .orderBy("lastName");
    var docs = await docRef.get();

    if (!docs.empty) {
      commit(
        "SET_CANCELLED",
        docs.docs.map(doc => doc.data())
      );
    } else {
      console.log("Could not find checkedin list.");
    }
    checkedinListener = docRef.onSnapshot(function(snapshot) {
      let update = snapshot.docs;
      console.log("checked in list updated", update);
      commit(
        "SET_CANCELLED",
        update.map(doc => {
          var ed = doc.data();
          if (!ed.id) ed.id = doc.id;
          return ed;
        })
      );
    });
  },
  async fetchAudience({ commit }, request) {
    var db = firebase.firestore();
    console.log("Getting audience report", request.eventId, request.dateId);
    var docRef = db
      .collection("events")
      .doc(request.eventId)
      .collection("userData")
      .doc("audienceReport")
      .collection("dates")
      .doc(request.dateId)
      .collection("audience")
      .where("status", "in", ["checkedin", "partiallycheckedin", "paid"])
      .orderBy("lastName");
    var docs = await docRef.get();

    audienceRef = docRef;
    audienceListener = docRef.onSnapshot(function(snapshot) {
      let update = snapshot.docs;
      console.log("audience updated", update);
      commit(
        "SET_AUDIENCE",
        update.map(doc => doc.data())
      );
    });

    if (!docs.empty) {
      commit(
        "SET_AUDIENCE",
        docs.docs.map(doc => doc.data())
      );
    } else {
      console.log("Could not find audience for this event.");
    }
  },
  async fetchAudienceTemp({ commit }, request) {
    var db = firebase.firestore();
    console.log("Getting audience report temp", request.eventId, request.dateId);
    var docRef = db
      .collection("events")
      .doc(request.eventId)
      .collection("userData")
      .doc("audienceReport")
      .collection("dates")
      .doc(request.dateId)
      .collection("audience")
      .where("status", "in", ["checkedin", "partiallycheckedin", "paid"])
      .orderBy("lastName");
    var docs = await docRef.get();

    audienceRef = docRef;
    audienceListener = docRef.onSnapshot(function(snapshot) {
      let update = snapshot.docs;
      console.log("audience updated", update);
      commit(
        "SET_AUDIENCE",
        update.map(doc => doc.data())
      );
    });

    if (!docs.empty) {
      const audience = [];
      const audiencedocs = docs.docs.map(doc => doc.data());
      console.log("adogs", audiencedocs.length);
      for (let x = 0; x < audiencedocs.length; x++) {
        let docdata = audiencedocs[x];
        let artistname = "";
        var orderitems = await db
          .collection("orders")
          .doc(docdata.orderId)
          .collection("lineItems")
          .get();
        orderitems.forEach(li => {
          let lidata = li.data();
          artistname = lidata.artistName;
          console.log("Artist name", lidata.artistName);
        });
        docdata.artistName = artistname;
        audience.push(docdata);
      }
      commit("SET_AUDIENCE", audience);
    } else {
      console.log("Could not find audience for this event.");
    }
  }
};

export const getters = {
  eventSummary(state) {
    return state.eventSummary;
  },
  eventSummarys(state) {
    return state.eventSummarys;
  },
  festivalEventSummarys(state) {
    return state.festivalEventSummarys;
  },
  dateSummaries(state) {
    return state.dateSummaries;
  },
  dateSummary(state) {
    return state.dateSummary;
  },
  willcall(state) {
    return state.willcall;
  },
  audience(state) {
    return state.audience;
  },
  checkedin(state) {
    return state.checkedin;
  },
  cancelled(state) {
    return state.cancelled;
  },
  waitlist(state) {
    return state.waitlist;
  }
};
